import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 align="center">0x4e 0x4f 0x54 0x20 0x46 0x4f 0x55 0x4e 0x44</h1>
    <p align="center">Confused? So am I.</p>
    <p align="center">This is not the page you're looking for.</p>
  </Layout>
)

export default NotFoundPage
